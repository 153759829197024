<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('tradeTariffHsCode.hs_code') }} {{$t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <b-col xs="12" sm="12" md="4">
            <b-form-group
              label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{ $t('globalTrans.fiscal_year') }}
              </template>
              <b-form-select
                plain
                id="fiscal_year_id"
                :options="fiscalYearList"
                v-model="search.fiscal_year_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="HS Code" vid="hs_code" rules="" v-slot="{ errors }">
              <b-form-group
                label-for="hs_code">
                <template v-slot:label>
                  {{ $t('tradeTariffHsCode.hs_code') }}
                </template>
                <b-form-input
                  id="hs_code"
                  v-model="search.hs_code"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="9">
          </b-col>
          <b-col sm="3" style="text-align: right;">
            <b-button size="sm" type="submit" variant="primary" class="mt-20">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
        </b-form>
      </ValidationObserver>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('tradeTariffHsCode.hs_code') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ data.item.serial + pagination.slOffset }}
                  </template>
                  <template v-slot:cell(su)="data">
                    {{ data.item.su }}
                  </template>
                  <template v-slot:cell(cd)="data">
                    {{ data.item.cd }}
                  </template>
                  <template v-slot:cell(rd)="data">
                    {{ data.item.rd }}
                  </template>
                  <template v-slot:cell(sd)="data">
                    {{ data.item.sd }}
                  </template>
                  <template v-slot:cell(vat)="data">
                    {{ data.item.vat }}
                  </template>
                  <template v-slot:cell(ait)="data">
                    {{ data.item.ait }}
                  </template>
                  <template v-slot:cell(atv)="data">
                    {{ data.item.atv }}
                  </template>
                  <template v-slot:cell(tti)="data">
                    {{ data.item.tti }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-success" v-if="data.item.status == 2">{{$t('globalTrans.approved')}}</span>
                    <span class="badge badge-danger" v-else>{{$t('globalTrans.pending')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                   <b-button v-b-modal.modal-form variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                    <b-button v-b-modal.modal-details variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)" class="action-btn edit" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :items="items" :key="editItemId"/>
    </b-modal>
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form.vue'
import Details from './Details.vue'
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { hsCodeProfileList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
        // fiscal_year_id: this.$store.state.currentFiscalYearId,
        fiscal_year_id: 0,
        hs_code: '',
        limit: 10
      },
      items: [],
      warehouseList: [],
      viewitemId: 0,
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'tradeTariffHsCode.hs_code', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '10%' } },
        { labels: 'globalTrans.description', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 3, thStyle: { width: '10%' } },
        { labels: 'tradeTariffHsCode.su', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 4, thStyle: { width: '5%' } },
        { labels: 'tradeTariffHsCode.cd', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 5, thStyle: { width: '5%' } },
        { labels: 'tradeTariffHsCode.rd', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 6, thStyle: { width: '5%' } },
        { labels: 'tradeTariffHsCode.sd', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 7, thStyle: { width: '5%' } },
        { labels: 'tradeTariffHsCode.vat', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 8, thStyle: { width: '5%' } },
        { labels: 'tradeTariffHsCode.ait', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 9, thStyle: { width: '5%' } },
        { labels: 'tradeTariffHsCode.atv', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 10, thStyle: { width: '5%' } },
        { labels: 'tradeTariffHsCode.tti', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 11, thStyle: { width: '5%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 12, thStyle: { width: '5%' } }
      ],
      actions: {
        forward: false,
        edit: false,
        details: true,
        toogle: false,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'hs_code' },
          { key: 'description' },
          { key: 'su' },
          { key: 'cd' },
          { key: 'rd' },
          { key: 'sd' },
          { key: 'vat' },
          { key: 'ait' },
          { key: 'atv' },
          { key: 'tti' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'hs_code' },
          { key: 'description' },
          { key: 'su' },
          { key: 'cd' },
          { key: 'rd' },
          { key: 'sd' },
          { key: 'vat' },
          { key: 'ait' },
          { key: 'atv' },
          { key: 'tti' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('tradeTariffHsCode.hs_code') + ' ' + this.$t('globalTrans.entry') : this.$t('tradeTariffHsCode.hs_code') + ' ' + this.$t('globalTrans.modify')
    },
    viewTitle () {
      return this.$t('tradeTariffHsCode.hs_code') + ' ' + this.$t('globalTrans.details')
    }
  },
  methods: {
      edit (item) {
        this.editItemId = item.id
        this.items = item
      },
      details (item) {
        this.items = item
      },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(tradeTariffServiceBaseUrl, hsCodeProfileList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
