// hs code management Routes......

// hs code profile routes.........
const hsCodeProfile = '/hs-code-management/hs-code-profile/'
export const hsCodeProfileList = hsCodeProfile + 'list'
export const hsCodeProfileStore = hsCodeProfile + 'store'
export const hsCodeProfileUpdate = hsCodeProfile + 'update'
export const hsCodeProfileDelete = hsCodeProfile + 'delete'

export const searchHsCode = '/hs-code-management/search-hs-code'
