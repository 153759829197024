<template>
    <div>
    <ValidationObserver ref="viewForm" v-slot="{ handleSubmit, reset }">
      <b-overlay :show="loading">
        <b-form @submit.prevent="handleSubmit(viewHsCode)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Section Number" vid="section_id" rules="required|min_value:1" v-slot="{ errors }">
                <b-form-group
                  label-for="section_id">
                  <template v-slot:label>
                    {{ $t('tradeTariffConfig.section_no') }} <span class="text-danger"> *</span>
                  </template>
                  <b-form-select
                    plain
                    id="section_id"
                    :options="sectionList"
                    v-model="form.section_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Chapter Number" vid="chapter_id" rules="required|min_value:1" v-slot="{ errors }">
                <b-form-group
                  label-for="chapter_id">
                  <template v-slot:label>
                    {{ $t('tradeTariffConfig.chapter_no') }} <span class="text-danger"> *</span>
                  </template>
                  <b-form-select
                    plain
                    id="chapter_id"
                    :options="chapterList"
                    v-model="form.chapter_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Heading Number" vid="heading_id" rules="required|min_value:1" v-slot="{ errors }">
                <b-form-group
                  label-for="heading_id">
                  <template v-slot:label>
                    {{ $t('tradeTariffConfig.heading_no') }} <span class="text-danger"> *</span>
                  </template>
                  <b-form-select
                    plain
                    id="heading_id"
                    :options="headingList"
                    v-model="form.heading_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <ValidationProvider name="Sub Heading Number" vid="sub_heading_id" rules="" v-slot="{ errors }">
                <b-form-group
                  label-for="sub_heading_id">
                  <template v-slot:label>
                    {{ $t('tradeTariffConfig.sub_heading_no') }}
                  </template>
                  <b-form-select
                    plain
                    id="sub_heading_id"
                    :options="subHeadingList"
                    v-model="form.sub_heading_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <button type="submit" class="btn btn-success">{{$t('globalTrans.view')}}</button>
            </b-col>
          </b-row>
      </b-form>
      </b-overlay>
    </ValidationObserver>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-overlay :show="loading">
        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <div v-if="showDetails">
          <b-row class="mt-3 mb-3">
            <template>
                <div style="font-size:18px; background-color: #17a2b8;border-radius:5px" class="p-1">
                    <h5 class="text-white text-left">
                        {{ $t('tradeTariffHsCode.hs_code') }} {{ $t('globalTrans.details') }}
                    </h5>
                </div>
              </template>
              <p class="mt-1 text-center">{{$t('tradeTariffHsCode.hs_code')}} : {{form.hs_code}}</p>
              <p class="mt-1 text-center">{{$t('tradeTariffApp.product_name')}} : {{ form.product_name }}</p>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="HS Code" vid="hs_code" rules="required" v-slot="{ errors }">
                    <b-form-group
                      label-for="hs_code">
                      <template v-slot:label>
                        {{ $t('tradeTariffHsCode.hs_code') }}<span class="text-danger"> *</span>
                      </template>
                      <b-form-input
                        id="hs_code"
                        v-model="form.hs_code"
                        disabled
                        :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="form.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <ValidationProvider name="Description" vid="description" rules="required">
                        <b-form-group
                            class="row"
                            label-for="description"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{$t('globalTrans.description')}}<span class="text-danger"> *</span>
                        </template>
                        <b-textarea
                            id="description"
                            v-model="form.description"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-textarea>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="SU" vid="su" rules="required" v-slot="{ errors }">
                      <b-form-group
                        label-for="su">
                        <template v-slot:label>
                          {{ $t('tradeTariffHsCode.su') }}<span class="text-danger"> *</span>
                        </template>
                        <b-form-input
                          id="su"
                          v-model="form.su"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="CD" vid="cd" rules="required" v-slot="{ errors }">
                      <b-form-group
                        label-for="cd">
                        <template v-slot:label>
                          {{ $t('tradeTariffHsCode.cd') }}<span class="text-danger"> *</span>
                        </template>
                        <b-form-input
                          id="cd"
                          v-model="form.cd"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="RD" vid="rd" rules="required" v-slot="{ errors }">
                      <b-form-group
                        label-for="rd">
                        <template v-slot:label>
                          {{ $t('tradeTariffHsCode.rd') }}<span class="text-danger"> *</span>
                        </template>
                        <b-form-input
                          id="rd"
                          v-model="form.rd"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <ValidationProvider name="SD" vid="sd" rules="required" v-slot="{ errors }">
                      <b-form-group
                        label-for="sd">
                        <template v-slot:label>
                          {{ $t('tradeTariffHsCode.sd') }}<span class="text-danger"> *</span>
                        </template>
                        <b-form-input
                          id="sd"
                          v-model="form.sd"
                          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                          :state="errors[0] ? false : (valid ? true : null)"
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="AIT" vid="ait" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-for="ait">
                    <template v-slot:label>
                      {{ $t('tradeTariffHsCode.ait') }}<span class="text-danger"> *</span>
                    </template>
                    <b-form-input
                      id="ait"
                      v-model="form.ait"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="ATV" vid="atv" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-for="atv">
                    <template v-slot:label>
                      {{ $t('tradeTariffHsCode.atv') }}<span class="text-danger"> *</span>
                    </template>
                    <b-form-input
                      id="atv"
                      v-model="form.atv"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="TTI" vid="tti" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-for="tti">
                    <template v-slot:label>
                      {{ $t('tradeTariffHsCode.tti') }}<span class="text-danger"> *</span>
                    </template>
                    <b-form-input
                      id="tti"
                      v-model="form.tti"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="VAT" vid="vat" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-for="vat">
                    <template v-slot:label>
                      {{ $t('tradeTariffHsCode.vat') }}<span class="text-danger"> *</span>
                    </template>
                    <b-form-input
                      id="vat"
                      v-model="form.vat"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Export Duty (%)" vid="export_duty" rules="required|min_value:0|max_value:100" v-slot="{ errors }">
                  <b-form-group
                    label-for="export_duty">
                    <template v-slot:label>
                      {{ $t('tradeTariffHsCode.export_duty') }}<span class="text-danger"> *</span>
                    </template>
                    <b-form-input
                      id="export_duty"
                      v-model="form.export_duty"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Import Duty (%)" vid="import_duty" rules="required|min_value:0|max_value:100" v-slot="{ errors }">
                  <b-form-group
                    label-for="import_duty">
                    <template v-slot:label>
                      {{ $t('tradeTariffHsCode.import_duty') }}<span class="text-danger"> *</span>
                    </template>
                    <b-form-input
                      id="import_duty"
                      v-model="form.import_duty"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Sro-Reference" vid="sro_reference" rules="required" v-slot="{ errors }">
                  <b-form-group
                    label-for="sro_reference">
                    <template v-slot:label>
                      {{ $t('tradeTariffHsCode.sro_reference') }}<span class="text-danger"> *</span>
                    </template>
                    <b-form-input
                      id="sro_reference"
                      v-model="form.sro_reference"
                      :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              </b-col>
               <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Export Attachment" vid="export_attachment" :rules="attachmentReq">
                    <b-form-group
                      label-for="export_attachment"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                       {{$t('tradeTariffHsCode.export')}} {{$t('globalTrans.attachment')}} <span class="text-danger">{{ $t('tradeTariffHsCode.attachment_type') }} *</span>
                      </template>
                    <b-form-file id="export_attachment"
                    v-model="form.export_file"
                    v-on:change="onFileChange"
                    accept=".doc,.docx,.pdf"
                    class="mt-2" plain
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-file>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div v-if="exportError" class="text-danger">
                        {{ exportError }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
               <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                  <ValidationProvider name="Import Attachment" vid="import_attachment" :rules="attachmentReq">
                    <b-form-group
                      label-for="import_attachment"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                       {{$t('tradeTariffHsCode.import')}} {{$t('globalTrans.attachment')}} <span class="text-danger">{{ $t('tradeTariffHsCode.attachment_type') }} *</span>
                      </template>
                    <b-form-file id="import_attachment"
                    v-model="form.import_file"
                    v-on:change="importFileChange"
                    accept=".doc,.docx,.pdf"
                    class="mt-2" plain
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-file>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div v-if="importError" class="text-danger">
                        {{ importError }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
              </b-col>
          </b-row>
        </div>
          <b-row class="text-right">
            <b-col>
                <b-button type="submit" :disabled="!showDetails" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
      </ValidationObserver>
    </div>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { hsCodeProfileStore, hsCodeProfileUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id', 'secretQuestionList'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      userList: [],
      attachmentReq: 'required',
      exportError: 0,
      importError: 0,
      form: {
        fiscal_year_id: this.$store.state.currentFiscalYearId,
        hs_code: 0,
        product_name: '',
        section_id: 0,
        chapter_id: 0,
        heading_id: 0,
        sub_heading_id: 0,
        su: 0,
        cd: 0,
        rd: 0,
        sd: 0,
        ait: 0,
        atv: 0,
        tti: 0,
        vat: 0,
        import_duty: '',
        export_duty: '',
        sro_reference: '',
        description: '',
        export_attachment: '',
        import_attachment: ''
      },
      showDetails: false,
      chapterList: [],
      headingList: [],
      subHeadingList: []
    }
  },
  created () {
    if (this.id) {
      this.attachmentReq = ''
      this.showDetails = true
      const tmp = this.getData()
      this.form = tmp
      this.form.import_attachment = ''
      this.form.export_attachment = ''
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    sectionList () {
      return this.$store.state.TradeTariffService.commonObj.sectionNoList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.localizeUserList(newVal)
      }
    },
    'form.section_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.chapterList = this.getChapterList(newVal)
      } else {
        this.chapterList = []
      }
    },
    'form.chapter_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.headingList = this.getHeadingList(newVal)
      } else {
        this.headingList = []
      }
    },
    'form.heading_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.subHeadingList = this.getSubHeadingList(newVal)
      } else {
        this.subHeadingList = []
      }
    }
  },
  methods: {
     onFileChange (event) {
          const input = event.target
          if (input.files && input.files[0]) {
            const file = input.files[0]
            const maxSize = 5 * 1024 * 1024
            if (file.size > maxSize) {
              this.exportError = 'File is more than 5MB'
            } else {
              this.exportError = 0
            }
            const reader = new FileReader()
            reader.onload = (e) => {
              this.form.export_attachment = e.target.result
            }
            reader.readAsDataURL(input.files[0])
          } else {
            this.form.export_attachment = ''
          }
      },
     importFileChange (event) {
          const input = event.target
          if (input.files && input.files[0]) {
            const file = input.files[0]
            const maxSize = 5 * 1024 * 1024
            if (file.size > maxSize) {
              this.importError = 'File is more than 5MB'
            } else {
              this.importError = 0
            }
            const reader = new FileReader()
            reader.onload = (e) => {
              this.form.import_attachment = e.target.result
            }
            reader.readAsDataURL(input.files[0])
          } else {
            this.form.import_attachment = ''
          }
      },
    getChapterList (id) {
      return this.$store.state.TradeTariffService.commonObj.chapterNoList.filter(item => item.status === 1 && item.section_id === id)
    },
    getHeadingList (id) {
      return this.$store.state.TradeTariffService.commonObj.headingList.filter(item => item.status === 1 && item.chapter_id === id)
    },
    getSubHeadingList (id) {
      return this.$store.state.TradeTariffService.commonObj.subHeadingList.filter(item => item.status === 1 && item.heading_id === id)
    },
   async viewHsCode () {
        // const section = this.$store.state.TradeTariffService.commonObj.sectionNoList.find(item => item.value === this.form.section_id)
        // const chpater = this.$store.state.TradeTariffService.commonObj.chapterNoList.find(item => item.value === this.form.chapter_id)
        const heading = this.$store.state.TradeTariffService.commonObj.headingList.find(item => item.value === this.form.heading_id)
        const subHeading = this.$store.state.TradeTariffService.commonObj.subHeadingList.find(item => item.value === this.form.sub_heading_id)

        // let hsCode = section.number + chpater.number + heading.number
        let hsCode = heading.number
        let productName = heading.heading_name_en
        if (subHeading !== undefined && subHeading.number > 0) {
          hsCode = hsCode + subHeading.number
          productName = ''
          productName = subHeading.sub_heading_name_en
        }
        this.form.hs_code = hsCode
        this.form.product_name = productName
        this.showDetails = true
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      const form = this.form
      if (this.id) {
        result = await RestApi.putData(tradeTariffServiceBaseUrl, `${hsCodeProfileUpdate}/${this.id}`, form)
      } else {
        result = await RestApi.postData(tradeTariffServiceBaseUrl, hsCodeProfileStore, form)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
